import React from 'react';
import mapIcon from '../images/mapIcon.png';
import slika from '../images/news4.png';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
const locations = [
  {
    name: 'Paket Shop Trn',
    address: 'Cara Dušana 64 a (Laktaši)',
    location: [44.8468377, 17.2279161]
  },
  {
    name: 'Paket shop Banja Luka',
    address: 'Jovana Dučića 23a',
    location: [44.7761957, 17.1815013]
  },
  {
    name: 'Paket shop Mostar',
    address: 'Kneza Branimira 12',
    location: [43.3402592, 17.7967773]
  },
  {
    name: 'Paket shop Vitez',
    address: 'Tržni centar GMS',
    location: [44.1702809, 17.7807685]
  },
  {
    name: 'Paket shop Grbavica',
    address: 'Derviša Numića do br. 6',
    location: [43.8488202, 18.3944379]
  },
  {
    name: 'Paket shop Živinice',
    address: 'Druga ulica 64',
    location: [44.4466279, 18.6504534]
  },
  {
    name: 'Paket shop Tuzla',
    address: 'Tržni centar Sjenjak bb',
    location: [44.5173592, 18.7301085]
  },
  {
    name: 'Paket shop Bijeljina',
    address: 'Nikole Tesle 21',
    location: [44.7557716, 19.2141986]
  },
  {
    name: 'Paket shop Gračanica',
    address: 'Zlatnih ljiljana 22',
    location: [44.691537, 18.2982606]
  },


  {
    name: 'Distributivni centar Banja Luka',
    address: 'Jovana Dučića 23a (krug Čajaveca)',
    location: [44.7760925, 17.18368989999999]
  },
  {
    name: 'Distributivni centar Sarajevo',
    address: 'Kasindolskog bataljona 2b',
    location: [43.8172659, 18.358181100000024]
  },
  {
    name: 'Distributivni centar Foča',
    address: 'Svetosavska 56',
    location: [43.51393240000001, 18.779456900000014]
  },
  {
    name: 'Distributivni centar Trebinje',
    address: 'Lastvanska 24, naselje Gorica',
    location: [42.717899100000004, 18.35736040000006]
  },
  {
    name: 'Distributivni centar Mostar',
    address: 'Bišće polje bb',
    location: [43.31075250000004, 17.833229899999992]
  },
  {
    name: 'Distributivni centar Bijeljina',
    address: 'Stefana Dečanskog bb',
    location: [44.76838680000003, 19.167387500000018]
  },
  {
    name: 'Distributivni centar Doboj',
    address: 'Trebavskih srpskih brigada bb',
    location: [44.757309000000014, 18.082486000000017]
  },
  {
    name: 'Distributivni centar Brčko',
    address: 'Lončari bb',
    location: [44.94095300000001, 18.679483000000005]
  },
  {
    name: 'Distributivni centar Bihać',
    address: 'Ivana Frane Jukića br. 60, industrijska zona - naselje Vedro Polje',
    location: [44.80778130000001, 15.838926399999991]
  },
  {
    name: 'Distributivni centar Zenica',
    address: 'Blatuša bb',
    location: [44.214040999999995, 17.91336899999999]
  },
  {
    name: 'Distributivni centar Tuzla',
    address: 'Lukavac - Industrijska zona',
    location: [44.52154720000001, 18.526296900000034]
  },
  {
    name: 'Distributivni centar Bugojno',
    address: 'Gromile I 2',
    location: [44.067799700000016, 17.44984569999997]
  },
  {
    name: 'Distributivni centar Prijedor',
    address: 'Svale bb',
    location: [44.97726309999999, 16.732714200000032]
  },

];

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {
      show: false
    };
  }

  initMap() {
    this.setState({
      _mapInit: true
    });
    var latLng = new window.google.maps.LatLng(44.0044293, 17.7721895);

    var map = new window.google.maps.Map(this.GoogleMap, {
      zoom: 7.7,
      center: latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      gestureHandling: "gestureHandling",
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#c1cddf"
            },
            {
              "weight": 4
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    });

    for (let i = 0; i < locations.length; i++) {
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(locations[i].location[0], locations[i].location[1]),
        map: map,
        icon: mapIcon
      });
      marker.addListener('click', () => {
        this.setState({ show:locations[i] })
      })
    }
  }




  componentDidMount() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }

  }

  componentDidUpdate() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }
  }

  render() {
    return (
      <>
        {
          this.props.mapinit ?
            <div className="googleMap" ref={(input) => { this.GoogleMap = input; }}>
              {this.state.show ?
                <div className="googleModal">
                  <div className="googleBox">
                    <button onClick={() => this.setState({ show: null })} className="fa fa-close" />
                    <div className="textBox">
                      <p><span>{this.state.show.name}</span></p>
                      <p>{this.state.show.address}</p>
                    </div>
                  </div>
                </div> : null}
            </div>

            : null

        }

      </>
    );
  }
}
