import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import Text from './fields/text';
import Select from './fields/select';
import Check from './fields/check';
import DateComponent from './fields/date';
import AnimatedButton from '../animatedButton';

import {
    Container,
    Row,
    Col,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown
} from 'reactstrap';


const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate,
    invert
}) => (

        <Check
            label={label}
            translate={translate}
            invert={invert}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )
const renderDateField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <DateComponent
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )



class SelectingFormValuesForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    //eyJraWQiOiI4OGI4NWQzYS04NDJhLTQ5MzgtOWRhYS1hMjkyNGRkY2JhYzEiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3ZWJzaXRlQGxvY2FsaG9zdC5sb2NhbGhvc3QiLCJpc3MiOiJFdXJvRXhwcmVzcyBkLm8ubyIsImVtYWlsIjoid2Vic2l0ZUBsb2NhbGhvc3QubG9jYWxob3N0In0.d7JEF_lfxvsPwwvCtgjfr2KRtdxb62JpEOaZ3-n-LPdxpyVg1mJnysrNhqQT3-3pdvwxdg618RxBEDdFk0M20w

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.vals) != JSON.stringify(this.props.vals)) {
            fetch(`http://gateway.euroexpress.ba:8083/api/v1/pub/calculate?calc=true`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(this.props.vals)
            }).then((res) => res.json()).then((result) => {
                console.log(result);
                this.props.change('shippmentMetadata.tezinaZapremine', result.tezinaZapremine);
                this.props.change('tmp', result);

                this.setState({
                    price: result.iznosSaPdv
                })
            })
        }
    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        const test = {
            "primaocMestoOpis": "78000",
            "tezina": null,
            "duzina": 0,
            "sirina": 0,
            "visina": 0,
            "tezinaZapremine": 0,
            "vrednostPosiljke": 0,
            "nacinNp": 0,
            "express": false,
            "sms": false,
            "ekonomikDostava": false,
            "tender": false,
            "povratOtpremnice": false,
            "naplataPouzecem": false,
            "brzaDostava": false,
            "osiguranje": false,
            "povratValute": false,
            "otvaranjePosiljke": false,
            "dostavaVikendom": false,
            "iznosNaplatePouzecem": 0,
            "vrstaPosiljkeSifra": 1,
            "vrstaPosiljkeOznaka": "Paket",
            "popustPs": 0,
            "napomena": "",
            "iznosBezPdv": 0,
            "iznosPdv": 0,
            "iznosSaPdv": 0
        }
        return (

            <form onSubmit={handleSubmit} className="form-wrapper">
                <Container>
                    <Row>
                        <Col lg="9">

                            <Container>
                                <Row>

                                    {this.state.nextStep ?
                                        <>
                                            <Col lg="12">
                                                <p>Datum slanja</p>
                                                <Field
                                                    name="date"
                                                    type="text"
                                                    component={renderDateField}
                                                    placeholder="Kalendar"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Obveznik plaćanja<span>*</span></p>
                                                <Field
                                                    name="paymentType"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={required}

                                                >
                                                    <option value={1}> Pošiljalac </option>
                                                    <option value={2}> Primalac </option>
                                                </Field>
                                            </Col>

                                            <Col lg="12">
                                                <p className="title-big">PODACI O POŠILJAOCU</p>
                                            </Col>

                                            <Col lg="12">
                                                <p>Ime i prezime<span>*</span></p>
                                                <Field
                                                    name="senderInformation.name"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ime i prezime"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Adresa<span>*</span></p>

                                                <Field
                                                    name="senderInformation.address"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ulica"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="6">
                                                <p>Mjesto<span>*</span></p>
                                                <Field
                                                    name="senderInformation.city"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Mjesto"
                                                    validate={required}

                                                />
                                            </Col>



                                            <Col lg="6">
                                                <p>Telefon<span>*</span></p>
                                                <Field
                                                    name="senderInformation.phone"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Broj telefona"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p className="title-big">PODACI O PRIMAOCU</p>
                                            </Col>

                                            <Col lg="12">
                                                <p>Ime i prezime<span>*</span></p>
                                                <Field
                                                    name="receiverInformation.name"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ime i prezime"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="12">
                                                <p>Adresa<span>*</span></p>

                                                <Field
                                                    name="receiverInformation.address"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Ulica"
                                                    validate={required}

                                                />
                                            </Col>


                                            <Col lg="6">
                                                <p>Mjesto<span>*</span></p>
                                                <Field
                                                    name="receiverInformation.city"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Mjesto"
                                                    validate={required}

                                                />
                                            </Col>



                                            <Col lg="6">
                                                <p>Telefon<span>*</span></p>
                                                <Field
                                                    name="receiverInformation.phone"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="Broj telefona"
                                                    validate={required}

                                                />
                                            </Col>
                                            <Col lg="3">
                                                <AnimatedButton type="submit" buttonText="POŠALJI"></AnimatedButton>
                                            </Col>


                                        </>
                                        :
                                        <>



                                            <Col lg="12">
                                                <p>Vrsta pošiljke<span>*</span></p>
                                                <Field
                                                    name="shippmentMetadata.vrstaPosiljkeOznaka"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={required}

                                                >
                                                    <option value="Paket"> Paket </option>
                                                    <option value="Dokument"> Dokument </option></Field>
                                            </Col>


                                            <Col lg="12">
                                                <p>Dimenzije</p>
                                                <p className="sub">Poželjno je unijeti i dimenzije Vaše pošiljke kako biste dobili precizniji iznos transporta</p>
                                            </Col>

                                            <Col lg="4">
                                                <Field
                                                    name="shippmentMetadata.duzina"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="dužina (cm)"
                                                    validate={required}

                                                ></Field>
                                            </Col>
                                            <Col lg="4">
                                                <Field
                                                    name="shippmentMetadata.sirina"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="širina (cm)"
                                                    validate={required}

                                                ></Field>
                                            </Col>

                                            <Col lg="4">
                                                <Field
                                                    name="shippmentMetadata.visina"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="visina (cm)"
                                                    validate={required}

                                                ></Field>
                                            </Col>


                                            <Col lg="12">
                                                <p>Volumetrijska masa (u kg)</p>
                                                <p className="sub">Volumetrijska težina se automatski obračunava na osnovu mase.</p>
                                                <Field
                                                    name="shippmentMetadata.tezinaZapremine"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="d x š x v/5000"
                                                    validate={required}

                                                />
                                            </Col>

                                            <Col lg="12">
                                                <p>Masa (u kg)<span>*</span></p>
                                                <Field
                                                    name="shippmentMetadata.tezina"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="0"
                                                    validate={required}

                                                />
                                            </Col>
                                            <Col lg="6">
                                                <p>Vrijednost Pošiljke<span>*</span></p>
                                                <Field
                                                    name="shippmentMetadata.vrednostPosiljke"
                                                    type="text"
                                                    component={renderTextField}
                                                    placeholder="0"
                                                    validate={required}

                                                />
                                            </Col>
                                            <Col lg="6" xs="12" className="check-wrap">

                                                <Field
                                                    name="shippmentMetadata.osiguranje"
                                                    component={renderCheckField}
                                                    label="Dodatno osiguranje"
                                                    validate={required}

                                                />
                                            </Col>

                                            <Col lg="12">
                                                <p>Otvaranje pošiljke<span>*</span></p>
                                                <p className="sub">Otvaranje pošiljke podrazumijeva da primalac može samo vizuelno da pregleda sadržaj pošiljke, bez isprobavanja i uključivanja</p>


                                            </Col>

                                            <Col lg="3" xs="6" className="">

                                                <Field
                                                    name="shippmentMetadata.otvaranjePosiljke"
                                                    component={renderCheckField}
                                                    label="Da"
                                                    validate={required}

                                                />
                                            </Col>
                                            <Col lg="3" xs="6" className="">

                                                <Field
                                                    name="shippmentMetadata.otvaranjePosiljke"
                                                    invert
                                                    component={renderCheckField}
                                                    label="Ne"
                                                    validate={required}

                                                />
                                            </Col>
                                            <Col lg="12">
                                                <p>Popust na EuroExpress lokaciju</p>
                                                <p className="sub">Otvaranje pošiljke podrazumijeva da primalac može samo vizuelno da pregleda sadržaj pošiljke, bez isprobavanja i uključivanja</p>
                                                <Field
                                                    name="shippmentMetadata.popustPs"
                                                    type="text"
                                                    component={renderSelectField}
                                                    placeholder="Molim vas odaberite"
                                                    validate={required}

                                                >
                                                    <option value="0">Šaljem standardno</option>
                                                    <option value="1">Šaljem iz paket šopa/magacina</option>
                                                    <option value="2">Šaljem u paket šop/magacina</option>
                                                    <option value="3">Šaljem iz paket šopa/magacina u paket šop/magacin</option>
                                                </Field>
                                            </Col>

                                            <Col lg="6" className="check-align">
                                                <p className="title">DODATNE USLUGE</p>
                                                <Field
                                                    name="shippmentMetadata.naplataPouzecem"
                                                    component={renderCheckField}
                                                    label="Naplata pouzećem"

                                                />
                                                <Field
                                                    name="shippmentMetadata.povratOtpremnice"
                                                    component={renderCheckField}
                                                    label="Povrat otpremnice"

                                                />
                                                <Field
                                                    name="shippmentMetadata.tender"
                                                    component={renderCheckField}
                                                    label="Tenderska dokumentacija"

                                                />
                                                <Field
                                                    name="shippmentMetadata.express"
                                                    component={renderCheckField}
                                                    label="Vremenski određena dostava"

                                                />
                                                <Field
                                                    name="shippmentMetadata.brzaDostava"
                                                    component={renderCheckField}
                                                    label="Ubrzana dostava (do 12h)"

                                                />
                                                <Field
                                                    name="shippmentMetadata.dostavaVikendom"
                                                    component={renderCheckField}
                                                    label="Dostava subotom"

                                                />

                                            </Col>


                                            <Col lg="6"></Col>


                                            <Col lg="3">
                                            <AnimatedButton type="button" buttonText="SLEDEĆI KORAK" handleClick={() => {this.setState({nextStep: true}); window.scrollTo({top: 0, behavior: 'smooth'})} }></AnimatedButton>
                                            </Col>
                                        </>
                                    }

                                </Row>
                            </Container>
                        </Col>

                        <Col lg="3">
                            <div className="price-modal">
                                <h6>Izračunata cijena dostave</h6>
                                <h3> {this.state.price ? this.state.price : '...'} KM</h3>
                                <p>* U cijene su uračunati PDV i takse</p>
                                <p>* Cijene su informativnog karaktera</p>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </form>
        )
    }
}
/*
export default reduxForm({
            form: 'sendPackageForm'  // a unique identifier for this form
    })(sendPackageForm)
    */

// Decorate with redux-form
SelectingFormValuesForm = reduxForm({
    form: 'selectingFormValues' // a unique identifier for this form
})(SelectingFormValuesForm)

// Decorate with connect to read form values
const selector = formValueSelector('selectingFormValues') // <-- same as form name
SelectingFormValuesForm = connect(state => {
    const values = selector(state, 'shippmentMetadata'
    )
    return {
        vals: values
    }
})(SelectingFormValuesForm)

export default SelectingFormValuesForm
