import React, { Component } from 'react'
import News from '../components/news';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import PageWithLayout from '../containers/page';
import Form from '../components/forms/form';
import {
    Container,
    Row,
    Col,
    NavItem, NavLink
} from 'reactstrap'

const token = 'eyJraWQiOiI4OGI4NWQzYS04NDJhLTQ5MzgtOWRhYS1hMjkyNGRkY2JhYzEiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ3ZWJzaXRlQGxvY2FsaG9zdC5sb2NhbGhvc3QiLCJpc3MiOiJFdXJvRXhwcmVzcyBkLm8ubyIsImVtYWlsIjoid2Vic2l0ZUBsb2NhbGhvc3QubG9jYWxob3N0In0.d7JEF_lfxvsPwwvCtgjfr2KRtdxb62JpEOaZ3-n-LPdxpyVg1mJnysrNhqQT3-3pdvwxdg618RxBEDdFk0M20w';

class sendPackage extends Component {
    constructor(props) {
        super(props);
        this.send = this.send.bind(this);

        this.state = {
        }
    }

    componentDidMount() {
     


    }
    send(data) {

        fetch('http://gateway.euroexpress.ba:8083/api/v1/pub/jobs/apply', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,

            },
            body: JSON.stringify(data)
        }).then((res) => { if (res.status == 200) return {} }).then((result) => {
            console.log('done')
            this.setState({
                _done: true
            })
        });

    }


    render() {
       


        return (
            <div>
                <Container>
                    <Row noGutters>
                        <div className="bredkramb">
                            <h6>Prijavi se</h6>
                            <div className="links">

                                <NavItem>
                                    <Link to="/">Početna</Link>
                                </NavItem>
                                <NavItem>
                                    <Link to="/zaposlenje/prijavi-se">Zaposlenje</Link>
                                </NavItem>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="universalPageWrapper">
                    <Row>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Container>
                                <Row>
                                    
                                    <Form onSubmit={this.send}></Form>
                                    {this.state._done ?
                                        <Col lg="12">
                                            <div class="alert alert-info mt-4" role="alert">Prijava uspješno poslata</div>
                                        </Col>
                                        :
                                        null
                                    }

                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default PageWithLayout(sendPackage)
