import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import About from './views/aboutUs/aboutHistory';
import DomesticTransport from './views/basicServices/domesticTransport';
import NewsPage from './views/news/newsPage';
import NewsDetailPage from './views/news/newsDetail.js';
import ShipmentTracking from './views/shipmentTracking.js';
import ContactForm from './views/contact/contactForm';
import ContactBranchNetwork from './views/contact/branchNetwork';
import ShippingInfoForForeginContries from './views/contact/shippingInfoForForeginContries';
import Education from './views/hiring/education';
import OpenPositions from './views/hiring/openPositions';
import './App.css';
import './animate.css';
import GoogleMap from './googleMapScript';
import ScrollIntoView from './scrollIntoView';
import SendPackage from './views/sendPackage';
import NewsMediaPage from './views/news/newsMediaPage';
import Hiring from './views/hiring';
import Zones from './views/zones';
import Static from './views/static';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;

    this.state = {
      static: {

      }
    };

  }
  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount() {
            fetch('https://euroexpress.novamedia.agency/static', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => res.json()).then((pages) => {
              this.setState({
                static:pages
              })
            })
    

  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <GoogleMap API_KEY="AIzaSyAaSt58UDZVRmMYe52a3cCPfJaoCaHUJqY" />
        <Router>
          <ScrollIntoView>
            <Switch>
            <Route exact path="/slanje-posiljke" render={(...renderProps) => (<SendPackage {...renderProps} {...this.state}></SendPackage>)} />

              <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state}></Home>)} />
              <Route exact path="/o-nama/:alias" render={(...renderProps) => (<About {...renderProps} {...this.state}></About>)} />
{/*              
              <Route exact path="/o-nama/misija-i-vizija" render={(...renderProps) => (<MissionAndVision {...renderProps} {...this.state}></MissionAndVision>)} />
              <Route exact path="/o-nama/uprava-kompanije" render={(...renderProps) => (<CompanyManagment {...renderProps} {...this.state}></CompanyManagment>)} />
              <Route exact path="/o-nama/mreža-naših-poslovnica" render={(...renderProps) => (<BranchNetwork {...renderProps} {...this.state}></BranchNetwork>)} />
*/}
              <Route exact path="/usluge/:parent/:alias" render={(...renderProps) => (<DomesticTransport {...renderProps} {...this.state}></DomesticTransport>)} />
              <Route exact path="/usluge/:alias" render={(...renderProps) => (<DomesticTransport {...renderProps} {...this.state}></DomesticTransport>)} />
              <Route exact path="/stranica/:alias" render={(...renderProps) => (<Static {...renderProps} {...this.state}></Static>)} />

              {/*<Route exact path="/dodatne-usluge/tenderska-dokumentacija" render={(...renderProps) => (<TenderDocumentation {...renderProps} {...this.state}></TenderDocumentation>)} />
              <Route exact path="/usluge-na-poseban-zahtjev/kurir-na-dan" render={(...renderProps) => (<SpecialRequestServices {...renderProps} {...this.state}></SpecialRequestServices>)} />
*/}
              
              <Route exact path="/novosti/aktuelna-dešavanja" render={(...renderProps) => (<NewsPage {...renderProps} {...this.state}></NewsPage>)} />
              <Route exact path="/novosti/kutak-za-medije" render={(...renderProps) => (<NewsMediaPage {...renderProps} {...this.state}></NewsMediaPage>)} />

              <Route exact path="/novosti/:id" render={(...renderProps) => (<NewsDetailPage {...renderProps} {...this.state}></NewsDetailPage>)} />
              <Route exact path="/praćenje-pošiljke" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />
              <Route exact path="/zaposlenje/stranica/:alias" render={(...renderProps) => (<Education {...renderProps} {...this.state}></Education>)} />
              <Route exact path="/kontakt" render={(...renderProps) => (<ContactForm {...renderProps} {...this.state}></ContactForm>)} />
              <Route exact path="/kontakt/mapa-distributivnih-centara" render={(...renderProps) => (<ContactBranchNetwork {...renderProps} {...this.state}></ContactBranchNetwork>)} />
              <Route exact path="/kontakt/informacije-za-inostrane-pošiljke" render={(...renderProps) => (<ShippingInfoForForeginContries {...renderProps} {...this.state}></ShippingInfoForForeginContries>)} />
              <Route exact path="/pracenje-posiljke" render={(...renderProps) => (<ShipmentTracking {...renderProps} {...this.state}></ShipmentTracking>)} />
              <Route exact path="/zaposlenje/prijavi-se" render={(...renderProps) => (<Hiring {...renderProps} {...this.state}></Hiring>)} />
              <Route exact path="/zone-dostava" render={(...renderProps) => (<Zones {...renderProps} {...this.state}></Zones>)} />

            </Switch>
          </ScrollIntoView>
        </Router>
      </div>
    )
  }
}

export default App;
